import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import SEO from "../components/global/seo"
import Layout from "../components/global/layout"
import styled from "styled-components"
import QuestionsSection from "../components/global/questions-section"
import Footer from "../components/global/footer"
import Text from "../components/elements/text/text"

import CallOutSection from "../components/our-research-page/call-out-section"
import StudyAreaSection from "../components/our-research-page/study-area-section"
import WhereFromSection from "../components/our-research-page/where-from-section"
import FindingsSection from "../components/our-research-page/findings-section"
import ResultsSection from "../components/our-research-page/results-section"

const TopWrapper = styled.div`
  background: ${p => p.theme.bgColorDark};
`

const FirstSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0.5rem;
  flex-direction: column;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`

const StyledLogo = styled(Img)`
  width: 145px;
`

const ResearchText = styled(Text)`
  font-size: 3.5rem;
  line-height: 3.5rem;
`

const Line = styled.hr`
  background: ${({ theme }) => theme.fontColorYellow};
  height: 0.35rem;
  margin: 1em 0 1.25em 0;
  width: 8rem;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`

const LogoImage = ({ data }) => {
  return (
    <StyledLogo
      objectFit="cover"
      alt="Permian Map Logo"
      fluid={data.Logo236w.childImageSharp.fluid}
    />
  )
}

const OurResearch = ({ data }) => (
  <Layout>
    <SEO title="Our Research" />
    <TopWrapper>
      <LogoWrapper>
        <StyledLink to="/">
          <LogoImage data={data} />
        </StyledLink>
      </LogoWrapper>
      <FirstSectionWrapper>
        <Text size="large" weight="bold">
          OUR
        </Text>
        <ResearchText font="xCompressed" size="xxxl" weight="bold">
          RESEARCH
        </ResearchText>
        <Line></Line>
      </FirstSectionWrapper>
      <CallOutSection />
      <StudyAreaSection />
    </TopWrapper>
    <WhereFromSection />
    <FindingsSection />
    <ResultsSection />
    <QuestionsSection />
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    Logo236w: file(relativePath: { eq: "logo-236w.png" }) {
      childImageSharp {
        fluid(maxWidth: 236, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default OurResearch
