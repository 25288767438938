import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { ParagraphText } from "../common-elements"

const Wrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: stretch;
  position: relative;
  @media (max-width: 890px) {
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 500px;
  }
`

const CalloutWrapper = styled.div`
  flex: 1;
  position: relative;
`

const YellowSection = styled.div`
  padding: 4.5rem 6rem;
  background: ${({ theme }) => theme.fontColorYellow};
  position: relative;
  z-index: 10;
  @media (max-width: 890px) {
    padding: 2rem;
    width: 500px;
  }

  @media (max-width: 889px) {
    width: 400px;
  }

  @media (max-width: 630px) {
    width: 330px;
  }

  @media (max-width: 360px) {
    width: 300px;
  }
`

const ImageWrapper = styled.div`
  margin: 2rem;
  margin-right: 0;
  flex: 1;
  height: auto;
  @media (max-width: 890px) {
    position: absolute;
    bottom: 0;
    height: 400px;
    width: 400px;
    right: -60px;
  }

  @media (max-width: 889px) {
    height: 400px;
    width: 400px;
    right: 60;
  }

  @media (max-width: 630px) {
    height: 400px;
    width: 300px;
    right: 0;
  }
`

const StyledHeroImage = styled(Img)`
  height: 80%;
  width: 100%;
  margin: 0;
  @media (max-width: 1330px) {
    height: 90%;
  }
  @media (max-width: 1300px) {
    height: 95%;
  }
  @media (max-width: 1280px) {
    height: 98%;
  }
`

const Spacer = styled.div`
  height: 150px;
  z-index: 10;
  position: relative;
  @media (max-width: 630px) {
  }
`

const StyledStripes = styled(Img)`
  position: relative;
  top: 3em;
  left: 12em;
  @media (max-width: 890px) {
    left: -225px;
    top: 18px;
  }

  @media (max-width: 889px) {
    left: -100px;
    top: 18px;
  }

  @media (max-width: 630px) {
    left: -100px;
    top: 18px;
  }
}
`

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        Hero808w: file(relativePath: { eq: "research-hero-808w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 808, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        HeroMobile400w: file(
          relativePath: { eq: "research-hero-mobile-400w.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Stripes1462w: file(relativePath: { eq: "tall_stripes.png" }) {
          childImageSharp {
            fluid(maxWidth: 1462, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const HeroImage = ({ data }) => {
    const sources = [
      data.Hero808w.childImageSharp.fluid,
      {
        ...data.HeroMobile400w.childImageSharp.fluid,
        media: `(max-width: 890px)`,
      },
    ]

    return (
      <StyledHeroImage
        objectFit="cover"
        alt="An aerial view of a natural gas refinery emitting methane emissions"
        fluid={sources}
      />
    )
  }

  const StripesImage = ({ data }) => {
    return (
      <StyledStripes
        objectFit="cover"
        alt="Yellow stripes element"
        fluid={data.Stripes1462w.childImageSharp.fluid}
      />
    )
  }

  return (
    <Wrapper>
      <CalloutWrapper>
        <YellowSection>
          <ParagraphText size="medium" weight="bold" color="bgLight">
            Beginning in 2019, EDF and our research partners used data from satellites, helicopters, aircraft, vehicles and towers to document the volume of methane emitted across our study areas.
          </ParagraphText>
        </YellowSection>
        <Spacer>
          <StripesImage data={data} />
        </Spacer>
      </CalloutWrapper>
      <ImageWrapper>
        <HeroImage data={data} />
      </ImageWrapper>
    </Wrapper>
  )
}
