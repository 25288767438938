import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { ParagraphText, TextSectionWrapper } from "../common-elements"
import ButtonPrimary from "../../elements/buttons/button-primary"
import Text from "../../elements/text/text"
import Media from "react-media"
import media from "../../../utils/media"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${p => p.theme.bgColorDark};
  position: relative;
  overflow: hidden;
  padding-bottom: 6.25rem;
`

const StyledTextSectionWrapper = styled(TextSectionWrapper)`
  margin: 0 2em;
  padding-top: 0;
  @media (max-width: 1550px) and (min-width: 1376px) {
    max-width: 700px;
    margin-left: 1rem;
  }

  @media (max-width: 1375px) and (min-width: 1301px) {
    max-width: 545px;
    margin-left: 1rem;
  }

  @media (max-width: 1300px) and (min-width: 1211px) {
    max-width: 460px;
    margin-left: 2rem;
  }

  @media (max-width: 1210px) and (min-width: 1151px) {
    max-width: 460px;
    margin-left: 2rem;
  }

  @media (max-width: 1150px) {
    margin-top: 2em;
  }

  ${media.tablet`
    max-width: 35rem;
  `};
`

const StyledButton = styled(ButtonPrimary)`
  margin: 0;
`

const VanContainer = styled.div`
  position: absolute;
  right: 0;
  overflow: hidden;
  padding-bottom: 25px;
  @media (max-width: 1150px) {
    width: 100%;
    height: 400px;
    position: relative;
    padding-bottom: 0;
  }
`

const StyledVanImage = styled(Img)`
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 10;
  margin: 0;
  width: 331px;
  @media (max-width: 1150px) {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 30px;
  }
`

const StyledStripesContainer = styled.div`
  position: absolute;
  bottom: -35px;
  left: 145px;
  margin: 0;
  @media (max-width: 1100px) {
    right: -100px;
    left: initial;
  }
`

const StyledStripes = styled(Img)`
  width: 370px;
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        Van336w: file(relativePath: { eq: "van-336w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 336, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Van991w: file(relativePath: { eq: "van-991w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 991, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Stripes370w: file(relativePath: { eq: "Side-Photo-b_Stripes@2x.png" }) {
          childImageSharp {
            fluid(maxWidth: 370, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ThinStripes136w: file(relativePath: { eq: "Stripes_thin.png" }) {
          childImageSharp {
            fluid(maxWidth: 136, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const VanImage = ({ data }) => {
    const sources = [
      data.Van991w.childImageSharp.fluid,
      {
        ...data.Van336w.childImageSharp.fluid,
        media: `(min-width: 1151px)`,
      },
    ]
    return (
      <StyledVanImage
        objectFit="cover"
        alt="A mobile methane detection vehicle with infrared camera attached"
        fluid={sources}
      />
    )
  }

  const StripesImage = ({ data }) => {
    return (
      <StyledStripes
        objectFit="cover"
        alt="Yellow stripes element"
        fluid={data.Stripes370w.childImageSharp.fluid}
      />
    )
  }

  return (
    <Wrapper>
      <VanContainer>
        <VanImage data={data} />
        <StyledStripesContainer>
          <StripesImage data={data} />
        </StyledStripesContainer>
      </VanContainer>
      <StyledTextSectionWrapper>
        <ParagraphText size="medium" color="yellow" weight="bold">
          From Towers
        </ParagraphText>
        <ParagraphText>
          Methane sensors installed at five towers across the study area are continuously measuring methane concentration on a 24-7 basis and will continue into 2023. Using an atmospheric transport model, Pennsylvania State University researchers observe how methane plumes move across the region each day. This provides us with monthly estimates of the region’s total methane emissions.
        </ParagraphText>
        <ParagraphText size="medium" color="yellow" weight="bold">
          From the Ground
        </ParagraphText>
        <ParagraphText>
          The University of Wyoming led the ground team. Equipped with a mobile methane detection vehicle and an infrared camera, they randomly surveyed several dozen sites to identify the specific locations and equipment that emit abnormal volumes of methane.
        </ParagraphText>
        <ParagraphText>
          This approach allowed us to measure lower, site-level emissions that may not be detectable by aircraft so that scientists can draw conclusions about the cause of emissions from all sources, not just the super-emitters.
        </ParagraphText>
        <ParagraphText size="medium" color="yellow" weight="bold">
          From Space
        </ParagraphText>
        <ParagraphText>
        We cross referenced our data with data gathered from the TROPOMI satellite, which shows the highest emissions ever measured from a major U.S. oil and gas basin.
        </ParagraphText>
        <StyledButton>
          <Media
            queries={{
              small: "(max-width: 1250px)",
              large: "(min-width: 1251px)",
            }}
            defaultMatches={{ large: true }}
          >
            {matches => (
              <>
                {matches.large && (
                  <StyledLink
                    href="https://www.edf.org/sites/default/files/documents/PermianMapMethodology_1.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    <Text uppercase weight="bold">
                      Download the full methodology PDF >>
                    </Text>
                  </StyledLink>
                )}
                {matches.small && (
                  <StyledLink
                    href="https://www.edf.org/sites/default/files/documents/PermianMapMethodology_1.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    <Text uppercase weight="bold">
                      Download the PDF >>
                    </Text>
                  </StyledLink>
                )}
              </>
            )}
          </Media>
        </StyledButton>
      </StyledTextSectionWrapper>
    </Wrapper>
  )
}
