import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { ParagraphText } from "../common-elements"
import Img from "gatsby-image/withIEPolyfill"
import Text from "../../elements/text/text"
import media from "../../../utils/media"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 2rem;
`

const StyledLogo = styled(Img)`
  margin: 0;
  width: 236px;
  margin-bottom: 1em;
`

const TextSectionWrapper = styled.div`
  max-width: 750px;
  padding: 2rem 0;
`

const StyledFeaturedPhoto = styled(Img)`
  max-width: 900px;
  width: 100%;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.5);
`

const PhotoWrapper = styled.div`
  min-width: 600px;

  ${media.phone`
    min-width: 300px;
  `};
`

const TextWrapper = styled.div`
  max-width: 550px;
  margin-left: 6rem;

  ${media.tablet`
    max-width: 35rem;
    margin-left: 4rem;
  `};

  ${media.phone`
    max-width: 25rem;
  `};
`

const StyledTextSectionWrapper = styled(TextSectionWrapper)`
  margin: 0 2em;
  padding-top: 0;
  @media (max-width: 1550px) and (min-width: 1376px) {
    max-width: 700px;
    margin-left: 1rem;
  }

  @media (max-width: 1375px) and (min-width: 1301px) {
    max-width: 545px;
    margin-left: 1rem;
  }

  @media (max-width: 1300px) and (min-width: 1211px) {
    max-width: 460px;
    margin-left: 2rem;
  }

  @media (max-width: 1210px) and (min-width: 1151px) {
    max-width: 460px;
    margin-left: 2rem;
  }

  ${media.tablet`
    max-width: 35rem;
  `};
`

const TowerWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TowerContainer = styled.div`
  position: absolute;
  left: 0;
  top: 7rem;
  padding-bottom: 25px;
  @media (max-width: 1150px) {
    width: 100%;
    height: 400px;
    top: 0;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 2rem;
  }
`

const StyledTowerImage = styled(Img)`
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 10;
  margin: 0;
  height: 600px;
  width: 300px;
  @media (max-width: 1150px) {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 30px;
  }
`

const StyledStripesContainer = styled.div`
  position: absolute;
  top: -50px;
  left: 20px;
  z-index: 20;
  margin: 0;

  @media (max-width: 1550px) and (min-width: 1376px) {
    top: -25px;
    left: -30px;
  }

  @media (max-width: 1375px) and (min-width: 1301px) {
    top: -25px;
    left: -30px;
  }

  @media (max-width: 1300px) and (min-width: 1211px) {
    top: -25px;
    left: -30px;
  }

  @media (max-width: 1210px) and (min-width: 1150px) {
    top: -25px;
    left: -30px;
  }
`

const StyledStripes = styled(Img)`
  margin: 0;
  height: 150px;
  width: 75px;
`

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        Logo236w: file(relativePath: { eq: "logo-236w.png" }) {
          childImageSharp {
            fluid(maxWidth: 236, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Plane900w: file(relativePath: { eq: "airplane-900w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        SensorTower375w: file(relativePath: { eq: "sensor-tower-375w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 375, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        SensorTower991w: file(relativePath: { eq: "Sensor-tower-991w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 991, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Stripes120w: file(relativePath: { eq: "Side-photo-a_Stripes@2x.png" }) {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const TowerImage = ({ data }) => {
    const sources = [
      data.SensorTower991w.childImageSharp.fluid,
      {
        ...data.SensorTower375w.childImageSharp.fluid,
        media: `(min-width: 1151px)`,
      },
    ]
    return (
      <StyledTowerImage
        objectFit="cover"
        alt="One of five sensor towers in the Permian Basin measuring methane 24/7"
        fluid={sources}
      />
    )
  }

  const StripesImage = ({ data }) => {
    return (
      <StyledStripes
        objectFit="cover"
        alt="Yellow stripes element"
        fluid={data.Stripes120w.childImageSharp.fluid}
      />
    )
  }

  return (
    <Wrapper>
      <StyledLogo
        objectFit="cover"
        alt="Permian Map Logo"
        fluid={data.Logo236w.childImageSharp.fluid}
      />
      <Text font="xCompressed" size="xxxl" weight="bold">
        DATA-GATHERING METHODOLOGY
      </Text>
      <StyledTextSectionWrapper>
        <ParagraphText>
        To ensure reliable data, we collected methane emissions in three ways: from the air, from towers and on the ground. The data was cross-referenced with TROPOMI satellite data in order to determine an estimate of methane volumes across the Permian Basin.
        </ParagraphText>
        <ParagraphText>
          Our team of atmospheric scientists and leak-detection experts spent more than 100 days in the air surveying the study area.
        </ParagraphText>
      </StyledTextSectionWrapper>
      <PhotoWrapper>
        <StyledFeaturedPhoto
          objectFit="cover"
          alt="Plane taking off for aerial serveillance of methane in the Permian Basin"
          fluid={data.Plane900w.childImageSharp.fluid}
        />
      </PhotoWrapper>
      <br />
      <TowerWrapper>
        <Text font="xCompressed" size="xxxl" weight="bold">
          DEFINING THE STUDY AREAS
        </Text>
        <StyledTextSectionWrapper>
          <ParagraphText>
            The majority of our measurements were taken in a 10,000 square-kilometer grid that spans the Delaware Basin, a subsection of the broader Permian Region. This high-producing area contains only 10% of the region’s active wells and produces about 40% of its oil and gas.
          </ParagraphText>
          <ParagraphText>
            Over 100 different companies operate the 11,000 wells in the study area. They range from big operators like Exxon, Shell and Chevron, to midsize players like Occidental Petroleum, and small independents that may only own one or two wells each.
          </ParagraphText>
          <ParagraphText>
            We also studied a portion of Midland Basin in order to compare emissions across different subsections of the Permian.
          </ParagraphText>
        </StyledTextSectionWrapper>
        <TowerContainer>
          <TowerImage data={data} />
          <StyledStripesContainer>
            <StripesImage data={data} />
          </StyledStripesContainer>
        </TowerContainer>
        <StyledTextSectionWrapper>
          <ParagraphText size="medium" color="yellow" weight="bold">
            From the Air
          </ParagraphText>
          <ParagraphText>
            Beginning in 2019, our team of atmospheric scientists and leak-detection experts spent more than 100 days in the air surveying the study area.
          </ParagraphText>
          <ParagraphText>
            Some flight paths were large, focused on characterizing the total emissions from large regions. Others were much smaller in scale, with the goal of zeroing in on a cluster of selected sites.
          </ParagraphText>
          <ParagraphText>
            Using methane sensors and the mass balance approach, our researchers with Scientific Aviation identified and quantified areas with high emissions levels and estimated leak rates across the study area. Sites were surveyed multiple times through the study period so that we could track changes and emissions improvements over time.
          </ParagraphText>
          <ParagraphText>
            We also worked with Leak Surveys Inc., a veteran leak detection company used by operators across the country, to gain insights about the prevalence of flare malfunctions and other large emission sources. LSI used a helicopter equipped with an infrared camera to conduct a random survey of thousands of flares across most of the Basin to determine their impact on the region’s methane emissions.
          </ParagraphText>
          <ParagraphText>
            Carbon Mapper researchers partnered with the PermianMAP project in the summer and fall of 2021, detecting nearly 1,700 plumes over 26 flight days.
          </ParagraphText>
          <ParagraphText>
            All aircraft research concluded in fall 2021.
          </ParagraphText>
        </StyledTextSectionWrapper>
      </TowerWrapper>
    </Wrapper>
  )
}
