import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { ParagraphText } from "../common-elements"
import Text from "../../elements/text/text"
import media from "../../../utils/media"

const FindingsSection = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  ${media.smallDesktop`
    background: ${p => p.theme.bgColorDark};
    flex-direction: column;
  `};
`

const FindingsImageWrapper = styled.div`
  flex: 1;
  display: flex;
  ${media.smallDesktop`
    position: absolute;
    height: 400px;
  `};
`

const FindingsSectionImage = styled(Img)`
  width: 100%;
  margin: 0;
  @media (max-width: 1060px) {
    width: 1060px;
  }
`

const FindingsTextSection = styled.div`
  flex: 1;
  ${media.smallDesktop`
    margin-top: 200px;
    position: relative;
    z-index: 10;
    padding: 1rem;
  `};
`

const FindingsTextWrapper = styled.div`
  padding: 4rem;
  ${media.smallDesktop`
    padding: 1rem;
    margin: 1rem auto;
    background: ${p => p.theme.bgColorLight};
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.5);
  `};
`
const DotText = styled(ParagraphText)`
  padding-left: 1.5rem;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    top: 12px;
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${p => p.theme.fontColorYellow};
  }
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.fontColorYellow};

  &:hover {
    color: ${({ theme }) => theme.fontColorYellowHover};
  }
`

const ComputerImage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        Computer840w: file(relativePath: { eq: "computer-840w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 840, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Computer1060w: file(relativePath: { eq: "computer-1060w.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1060, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const sources = [
    data.Computer1060w.childImageSharp.fluid,
    {
      ...data.Computer840w.childImageSharp.fluid,
      media: `(min-width: 1061px)`,
    },
  ]
  return (
    <FindingsSectionImage
      objectFit="cover"
      alt="Researcher reviewing data in mobile methane detection vehicle"
      fluid={sources}
    />
  )
}

export default () => {
  return (
    <FindingsSection>
      <FindingsImageWrapper>
        <ComputerImage />
      </FindingsImageWrapper>
      <FindingsTextSection>
        <FindingsTextWrapper>
          <Text font="xCompressed" size="xxxl" weight="bold" uppercase>
            Verifying the findings
          </Text>
          <ParagraphText>
            We used proven scientific methods to measure methane, and published our results in peer-reviewed scientific journals.
          </ParagraphText>
          <ParagraphText>
            Published work using data collected from this project:
          </ParagraphText>
          <DotText>
            Atmospheric Chemistry and Physics:{" "}
            <StyledLink
              href="https://acp.copernicus.org/articles/21/6605/2021/acp-21-6605-2021-discussion.html"
              target="_blank"
              rel="noopener"
            >
              Concurrent variation in oil and gas methane emissions and oil price during the COVID-19 pandemic
            </StyledLink>
          </DotText>
          <DotText>
            Nature Communications:{" "}
            <StyledLink
              href="https://www.nature.com/articles/s41467-022-29709-3"
              target="_blank"
              rel="noopener"
            >
              Methane emissions from US low production oil and natural gas well sites
            </StyledLink>
          </DotText>
          <DotText>
            Environmental Science and Technology:{" "}
            <StyledLink
              href="https://pubs.acs.org/doi/full/10.1021/acs.estlett.2c00380"
              target="_blank"
              rel="noopener"
            >
              Methane Emissions from Natural Gas Gathering Pipelines in the Permian Basin
            </StyledLink>
          </DotText>
          <DotText>
            Science:{" "}
            <StyledLink
              href="https://www.science.org/doi/10.1126/science.abq0385"
              target="_blank"
              rel="noopener"
            >
              Inefficient and unlit natural gas flares both emit large quantities of methane
            </StyledLink>
          </DotText>
          <Text font="xCompressed" size="xxl" weight="bold" uppercase>
            ADVISORY PANEL MEMBERS
          </Text>
          <DotText>
            Adam Brandt, Stanford University, Palo Alto, California
          </DotText>
          <DotText>
            Mary Kang, McGill University, Québec, Montréal, Canada
          </DotText>
          <DotText>
            Anthony Marchese, Colorado State University, Fort Collins, Colorado
          </DotText>
          <DotText>
            Eric Kort, University of Michigan, Ann Arbor, Michigan
          </DotText>
        </FindingsTextWrapper>
      </FindingsTextSection>
    </FindingsSection>
  )
}
