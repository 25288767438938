import React from "react"
import styled from "styled-components"
import { ParagraphText } from "../common-elements"
import media from "../../../utils/media"

const ResultsSection = styled.div`
  background: ${p => p.theme.bgColorDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem;

  ${media.tablet`
    padding: 2rem;
  `};
`

const ResultSectionTextWrapper = styled.div`
  text-align: center;
  max-width: 800px;
`

const StyledSubHeadline = styled(ParagraphText)`
  line-height: 1.406em;
`

const ResultSectionTextBottomWrapper = styled(ResultSectionTextWrapper)`
  max-width: 1000px;
`

const ResultScetionCardRow = styled.div`
  display: flex;
  max-width: 1750px;
  margin: 4rem 0;

  ${media.smallDesktop`
  flex-direction: column;
`};
`

const ResultSectionCard = styled.div`
  background: ${p => p.theme.bgColorLight};
  padding: 4rem 2rem;
  margin: 0 1rem;
  display: inline-block;
  flex: 1;
  max-width: 850px;

  ${media.smallDesktop`
    :nth-of-type(1) {
      margin-bottom: 2em;
    }
  `};
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.fontColorYellow};

  &:hover {
    color: ${({ theme }) => theme.fontColorYellowHover};
  }
`

export default () => {
  return (
    <ResultsSection>
      <ResultSectionTextWrapper>
        <ParagraphText font="xCompressed" size="xxxl" weight="bold" uppercase>
          Sharing the results
        </ParagraphText>
        <StyledSubHeadline size="medium" weight="bold" color="yellow">
          All data is available to the public at permianmap.org.
        </StyledSubHeadline>
      </ResultSectionTextWrapper>
      <ResultScetionCardRow>
        <ResultSectionCard>
          <ParagraphText color="yellow" weight="bold" uppercase>
            The Operator Performance Dashboard
          </ParagraphText>
          <ParagraphText>
            The Operator Performance Dashboard is designed to provide oil and
            gas companies, and other Permian Basin stakeholders, with data
            about operator performance as well as the location of
            measured methane events. We are also sending this data directly to
            the responsible companies so they can make immediate operational
            Improvements to decrease emissions.
          </ParagraphText>
          <ParagraphText>
            Operators and regulators have been notified about these emission
            events. Some emission events are temporary and may no longer be
            active or may have already been repaired. For a more detailed look
            at the data,{" "}
            <StyledLink
              href="https://data.permianmap.org/pages/operators"
              target="_blank"
              rel="noopener"
            >
              visit the full dashboard.
            </StyledLink>
          </ParagraphText>
          <ParagraphText>
            <StyledLink
              href="https://blogs.edf.org/energyexchange/files/2022/11/PermianMAPFinalReport.pdf"
              target="_blank"
              rel="noopener"
            >
              Read the final summary report.
            </StyledLink>
          </ParagraphText>
        </ResultSectionCard>
      </ResultScetionCardRow>
      <ResultSectionTextBottomWrapper>
        <ParagraphText>
          If a leak or emission is deemed a potential risk for explosion based
          on the location of the leak (near where people work, live or
          congregate), research teams will immediately notify the relevant local
          authorities.
        </ParagraphText>
      </ResultSectionTextBottomWrapper>
    </ResultsSection>
  )
}
